import { getBaseUrl } from '@utils/network';

console.log(process.env.NEXT_PUBLIC_API_URL);
console.log(process.env.NEXT_URL_BUILD);

export async function isSignedIn({ forwardToApp }) {
  try {
    const baseUrl = getBaseUrl();
    if (!baseUrl) {
      // in local dev mode this is disabled
      console.log('Is signed in check disabled on localhost');
      return;
    }
    const getCSRF = await fetch(`${baseUrl}/api/v1/util/csrf`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const data = await getCSRF.json();
    const { is_signed_in } = data;

    if (is_signed_in) {
      forwardToApp();
    }
  } catch (e) {
    console.error(e);
  }
}

export async function storeSessionParams(url) {
  try {
    await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  } catch (e) {
    console.log(e);
  }
}

export async function signUpEmail({ email, userSignUpComplete, userSignUpError, landerSlug }) {
  try {
    // -- Get initial CSRF token
    const getCSRF = await fetch(`${BASEURL}/api/v1/util/csrf`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    const data = await getCSRF.json();
    const { csrf_token } = data;

    const signUpEmail = await fetch(`${baseUrl}/api/v1/lead`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrf_token,
      },
      body: JSON.stringify({
        email,
        lander_slug: landerSlug,
      }),
    });

    const signUpData = await signUpEmail.json();

    if (signUpData.error) {
      userSignUpError(signUpData.error);
    } else {
      userSignUpComplete();
    }
  } catch (e) {
    console.error(e);
  }
}
