// import Adjust from '@adjustcom/adjust-web-sdk';
import React from 'react';
import { initializeAdjust, useScript } from '@lib/nvstr-common-ui.es';

import { LOG_ADJUST_EVENTS } from '../config';

let appToken = process.env.NEXT_ADJUST_TOKEN;
if (!appToken) {
  appToken = 'xgnikgxey680';
  console.warn('Falling back to backup adjust token');
}

let adjustEnvironment = process.env.NEXT_ADJUST_ENVIRONMENT;
if (!adjustEnvironment) {
  adjustEnvironment = 'sandbox';
  console.warn('Falling back to backup adjust env');
}

const adjustClassProperties = [
  'initSdk',
  'trackEvent',
  'addGlobalCallbackParameters',
  'addGlobalPartnerParameters',
  'removeGlobalCallbackParameter',
  'removeGlobalPartnerParameter',
  'clearGlobalCallbackParameters',
  'clearGlobalPartnerParameters',
  'switchToOfflineMode',
  'switchBackToOnlineMode',
  'stop',
  'restart',
  'gdprForgetMe',
  'disableThirdPartySharing',
  'initSmartBanner',
];

let hasInitialized = false;
export const InitializeAdjustWithConfig = () => {
  const uri = 'https://cdn.adjust.com/adjust-latest.min.js';

  const onBeforeLoad = () => {
    const binder = function (t, a, e) {
      t[e] = function () {
        a.push([e, arguments]);
      };
    };
    window.Adjust = window.Adjust || {};
    window.Adjust_q = window.Adjust_q || [];
    for (var c = 0; c < adjustClassProperties.length; c++)
      binder(window.Adjust, window.Adjust_q, adjustClassProperties[c]);
  };

  const status = useScript(uri, onBeforeLoad);

  React.useEffect(() => {
    if (status === 'ready' && !hasInitialized) {
      hasInitialized = true;

      const config = {
        logEvents: LOG_ADJUST_EVENTS,
      };
      // Adjust class is passed in to avoid common-lib dependencies from creating build errors
      initializeAdjust(window.Adjust, appToken, adjustEnvironment, config);
    }
    if (status === 'error') {
      console.error('unable to load script: ' + uri);
    }

    return () => {
      hasInitialized = false;
    };
  }, [status]);

  return null;
};
