import itly from './itly.service';

class ItlyTrackWrapper {
  constructor(eventName) {
    this.name = eventName;
  }

  page(currentScreen) {
    itly.page('Page Viewed', currentScreen);
  }

  send(properties) {
    itly.track(this.name, properties);
  }
}

export default ItlyTrackWrapper;
