var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
class TrackedRoute {
  constructor(urlPathName, appRouteName, trackingRouteName) {
    __publicField(this, "getTrackingRouteName", () => this.trackingRouteName);
    this._instance_trackedRoute = true;
    this.urlPathName = urlPathName;
    this.appRouteName = appRouteName;
    this.trackingRouteName = trackingRouteName;
  }
}
const appRoutes = {
  PROMO_REDEMPTION_SUCCESS: "Promo Redemption Success",
  SPLASH: "Splash",
  SPLASH_SIGN_IN: "Splash Sign In",
  SPLASH_SIGN_UP: "Splash Sign Up",
  DEV_TOOLS: "ModeSelect",
  DEV: "Dev",
  AUTHED_APP_INITIALIZATION: "AuthedAppInitialization",
  ONBOARDING_NAVIGATOR: "Onboarding Navigator",
  ONBOARDED_NAVIGATOR: "Onboarded Navigator",
  START_LIVE_TRADING_SIGN_UP: "StartLiveTradingSignUp",
  LT_INVESTING_EXPERIENCE: "LTInvestingExperience",
  ENTER_NAME: "Enter Name",
  ENTER_ADDRESS: "Enter Address",
  ENTER_PHONE: "Enter Phone",
  INVESTOR_PROFILE: "InvestorProfile",
  IDENTITY: "Identity",
  ENTER_DATE_OF_BIRTH: "Enter Date of Birth",
  DISCLOSURES: "Disclosures",
  AGREEMENTS: "Agreements",
  LIVE_ACCOUNT_SUBMITTED: "LiveAccountSubmitted",
  LT_REDEEM_REWARDS: "LiveTradingRedeemReferralRewards",
  ONBOARDING_FUNDING: "OnboardingFunding",
  ONBOARDING_ALMOST_THERE: "AlmostThereOnboarding",
  LT_FUNDING_DEPOSIT: "LiveTradingFundingTransfers",
  LT_DEFAULT_EXPECTED_RETURN: "LTDefaultExpectedReturn",
  START_PAPER_TRADING_SIGN_UP: "StartPaperTradingSignUp",
  PT_INVESTING_EXPERIENCE: "PTInvestingExperience",
  PT_DEFAULT_EXPECTED_RETURN: "PTDefaultExpectedReturn",
  START_ONBOARDING: "Onboarding",
  ONBOARDING_ACCOUNT_SELECTION: "OnboardingAccountSelection",
  HOME: "Home",
  ACTIVITY_FEED: "Activity",
  DASHBOARD: "Dashboard",
  TRADE: "Trade",
  FUNDING: "Funding",
  COMMUNITY: "Community",
  CLOSED_ACCOUNT: "ClosedAccount",
  SEARCH: "Search",
  EXPLORE: "Explore",
  SECURITY_PAGE: "Security",
  FILTERED_SECURITY_LIST: "FilteredSecurityList",
  SECURITY_LIVE_PRICING: "SecurityLivePricing",
  CREATE_ORDER: "NewOrder",
  CREATE_THOUGHT: "CreateThought",
  CONTACTS_IMPORT: "ContactsImport",
  COMMUNITY_SEARCH: "CommunitySearch",
  REFERRAL_ACTIONS: "ReferralActions",
  CONNECTIONS_LIST: "ConnectionsList",
  REWARDS: "Rewards",
  REDEEM_REWARDS: "RedeemReferralRewards",
  ACCOUNT: "Account",
  ACCOUNT_TRANSACTION_HISTORY: "Account Transaction History",
  LEGAL: "Legal",
  SUPPORT: "Support",
  CREATE_SUPPORT_REQUEST: "CreateSupportRequest",
  USER_SETTINGS: "UserSettings",
  USER_PROFILE: "Profile",
  VIEWPOINT_PROFILE: "Viewpoint Profile",
  VIEWPOINT_EXPLORE: "Explore All Viewpoints",
  EDIT_USER_PROFILE: "EditProfile",
  CHANGE_EMAIL: "ChangeEmail",
  CHANGE_PASSWORD: "ChangePassword",
  UPDATE_PREFERENCES: "UpdatePreferences",
  UPDATE_LIVE_ACCOUNT: "UpdateLiveAccount",
  LIVE_ACCOUNT_FURTHER_DOCUMENTATION_ENTRY: "LiveAccountFurtherDocumentationEntry",
  ONBOARDING_CONTACT_RESUBMIT: "OnboardingContactFormResubmit",
  CONTACT_RESUBMIT: "ContactFormResubmit",
  FUNDING_WITHDRAWAL: "FundingWithdrawal",
  FUNDING_DEPOSIT: "FundingDeposit",
  PAPER_FUNDING_TRANSFERS: "FundingTransferPaper",
  LINK_BANK_ACCOUNT: "LinkBankAccountModeSelection",
  LINK_BANK_ACCOUNT_PLAID: "LinkBankAccountPlaid",
  LINK_BANK_ACCOUNT_MICRO: "LinkBankAccountMicro",
  VERIFY_BANK_ACCOUNT: "VerifyBankAccount",
  MANAGE_BANK_ACCOUNTS: "ManageBankAccounts",
  INTERESTS_SELECTION: "InterestsSelection",
  INTEREST_WATCHLIST: "InterestsWatchList",
  PROMPT_PICK_OWN_STOCKS: "PickOwnStocksPrompt",
  CTL_RESPONSE: "CtlResponse",
  TRENDING_STOCKS: "TrendingStocks",
  GETTING_REWARDS_INTERSTITIAL: "GettingRewardsInterstitial",
  CREATE_SHARE: "CreateShare",
  UPCOMING_EARNINGS: "UpcomingEarnings",
  THOUGHTS_LIST: "ThoughtsList",
  PRIVACY_SETTING_SELECT: "PrivacySettingSelect",
  SITE_DISCLOSURES: "Site Disclosures",
  LEARN: "Learn",
  LEARN_REWARD_EARNED: "Learn Reward Earned",
  LEARN_DISCLAIMERS: "Learn Disclaimers",
  LEARN_LESSONS_COMPLETED: "Learn Lessons Completed",
  FINISH_ACCOUNT_SETUP: "Finish Account Setup",
  VIEWPOINT_WELCOME: "ViewpointWelcome",
  VIEWPOINT_SUBSCRIPTION_MANAGEMENT: "ViewpointSubscriptionManagement",
  VIEWPOINT_SUBSCRIPTION_SUCCESS: "ViewpointSubscriptionSuccess",
  PUSH_NOTIFICATION_DETAIL: "Push Notification Detail",
  MODAL: "Modal",
  USER_LIST_MODAL: "UserListModal",
  CLOSE_ACCOUNT_MODAL: "CloseAccountModal",
  SKIPPING_SIGNUP_REWARD_MODAL: "SkippingSignupRewardModal",
  MORE_INFO_ON_MARGIN_ACCOUNTS: "MoreInfoOnMarginAccountsModal",
  REMOVE_CONNECTION_MODAL: "RemoveConnectionModal",
  VIEWPOINT_SUBSCRIPTION_CONFIRMATION_MODAL: "ViewpointSubscribeConfirmationModal",
  START_LIVE_TRADING_NOW_ACTION_MODAL: "Start Live Trading Now Action Modal",
  CANCEL_VIEWPOINT_SUBSCRIPTION_MODAL: "CancelViewpointSubscriptionModal",
  INVITE_BY_EMAIL: "AddInviteEmailMessage",
  INFO_ICON_EXPLANATION: "InfoIconExplanation",
  ADD_IDEA_WITH_IDEA_TYPE_SELECTION: "AddIdea-IdeaTypeSelection",
  SECURITY_SCREEN_ACTIONS: "SecurityScreenActions",
  RATE_APP_MODAL: "Rate App Modal",
  OTA_UPDATE_APP_MODAL: "OTA Update Prompt Modal",
  FINISH_ACCOUNT_SETUP_MODAL: "Finish Account Setup Modal",
  SELECTION_MODAL: "Selection Modal",
  CONFIRM_EMAIL_ADDRESS_MODAL: "Confirm Email Address Modal",
  DISCLOSURES_MODAL: "Disclosures Modal",
  DONT_MISS_OUT_SIGN_UP_BONUS_MODAL: "Dont Miss Out Sign Up Bonus Modal",
  WITHDRAWAL_FUNDING_BREAKDOWN: "Withdrawal Funding Breakdown Modal",
  BROKERAGE_TRANSFER_MODAL: "Brokerage ACAT Transfer Request Modal"
};
const webappRoutes = {
  security: "/securities/:id",
  thoughtWithSlug: "/thoughts/:id/:thoughtSlug",
  thought: "/thoughts/:id",
  featuredViewpoints: "/viewpoint_contributors/featured",
  manageViewpoints: "/viewpoint_contributors/manage",
  viewpointProfile: "/viewpoint_contributors/:name",
  bdTerms: "/terms",
  socureDocSubmission: "/live-account-submission-status",
  resubmitAddress: "/resubmit-address",
  uploadDocuments: "/upload-required-documents",
  onboardingUploadDocuments: "/live_accounts/documentation",
  liveAccountSubmitConfirmation: "/live_accounts/confirmation",
  onboardingAlmostThere: "/almost-there",
  agreements: "/live_accounts/agreements",
  disclosures: "/live_accounts/disclosures",
  identity: "/live_accounts/identity",
  contact: "/live_accounts/contact",
  investorProfile: "/live_accounts/investor_profile",
  inviteFriends: "/invite-friends",
  trade: "/orders",
  myIdeas: "/ideas",
  onboardResumeProgress: "/onboard",
  portfolio: "/portfolio",
  portfolioHistory: "/portfolio/history",
  portfolioFunding: "/portfolio/funding",
  funding: "/funding",
  linkBank: "/funding/link-bank",
  withdrawFunds: "/funding/withdraw",
  depositFunds: "/funding/deposit",
  usersSignup: "/users/sign_up",
  login: "/login",
  signup: "/signup",
  editProfile: "/users/edit",
  connections: "/users/connections",
  userProfile: "/users/:id/public_profile",
  thoughtLeaders: "/users/thought_leaders",
  privacySettings: "/privacy_settings",
  ask: "/ask",
  rewards: "/referrals",
  rewardRedemption: "/rewards_redemption",
  learnAndEarn: "/learn-and-earn",
  learnAndEarnProgress: "/learn-and-earn-progress",
  learnAndEarnMoreInfo: "/learn-and-earn-more-info",
  selectAccountType: "/account_type",
  investingExperience: "/investing_experience",
  subscriptions: "/subscriptions",
  membership: "/membership",
  inviteFriendsFromPlatform: "/invites/provider",
  inviteFriendsFromPlatformSuccess: "/invites/:network/contact_callback",
  inviteFriendsFromPlatformFailure: "/invites/:network/contact_failure",
  inviteContactsFailure: "/contacts/failure",
  legal: "/legal",
  faq: "/faq",
  marginDisclosure: "/margin_disclosure",
  privacyPolicy: "/privacy_policy",
  tos: "/terms_of_service",
  tosNvstrFinancial: "/terms_of_service_nvstr_financial",
  tosNvstrTechnologies: "/terms_of_service_nvstr_technologies",
  referralRules: "/referral_rules",
  viewpointGuidelines: "/viewpoint_guidelines",
  shareLink: "/share/:token",
  eDocuments: "/documents",
  dashboard: "/",
  finishAccountSetup: "/finish-account-setup"
};
const PRODUCT_PLATFORMS = {
  mobileApp: "mobileApp",
  webApp: "webApp"
};
const trackingEvents = {
  identify: "identify",
  accountTypeSelected: "Account Type Selected",
  bankLinkSkipped: "Bank Link Skipped",
  buttonClicked: "Button Clicked",
  feedLoaded: "Feed Loaded",
  ideaViewed: "Idea Viewed",
  modalViewed: "Modal Viewed",
  onboardingCompleted: "Onboarding Completed",
  onboardingFundingSkipped: "Onboarding Funding Skipped",
  proConViewed: "Pro/Con Viewed",
  tabViewed: "Tab Viewed",
  pageViewed: "Page Viewed",
  securityViewed: "Security Viewed",
  signUpCreated: "Sign Up Created",
  userCreated: "User Created",
  viewpointSubscriptionPaid: "Viewpoint Subscription Paid",
  screenViewed: "Screen Viewed",
  bankLinked: "Bank Linked",
  brokerageAccountApproved: "Brokerage Account Approved",
  brokerageAccountCreated: "Brokerage Account Created",
  brokerageAccountSubmitted: "Brokerage Account Submitted",
  creditCardLinked: "Credit Card Linked",
  depositCompleted: "Deposit Completed",
  depositReturned: "Deposit Returned",
  depositSkipped: "Deposit Skipped",
  depositSubmitted: "Deposit Submitted",
  emailConfirmed: "Email Confirmed",
  highEquityReached: "High Equity Reached",
  ideaCopied: "Idea Copied",
  ideaCreated: "Idea Created",
  membershipPaid: "Membership Paid",
  orderCommissionPaid: "Order Commission Paid",
  orderExecuted: "Order Executed",
  orderPlaced: "Order Placed",
  orderScreenViewed: "Order Screen Viewed",
  proConCreated: "Pro/Con Created"
};
const types = {
  string: "string",
  number: "number",
  boolean: "boolean",
  object: "object"
};
class CommonValidationType {
  constructor(type, required, props = {}) {
    __publicField(this, "schema", {});
    __publicField(this, "isRequired", false);
    this.schema = __spreadValues({
      type
    }, props);
    this.isRequired = required;
  }
}
class StringType extends CommonValidationType {
  constructor(required, props = {}) {
    super(types.string, required, props);
  }
}
class NumberType extends CommonValidationType {
  constructor(required, props = {}) {
    super(types.number, required, props);
  }
}
class BooleanType extends CommonValidationType {
  constructor(required, props = {}) {
    super(types.boolean, required, props);
  }
}
class ObjectType {
  constructor(object = {}, config = {}) {
    __publicField(this, "schema", {});
    this.schema = __spreadProps(__spreadValues({
      type: types.object,
      properties: Object.entries(object).reduce((acc, cur) => {
        return __spreadProps(__spreadValues({}, acc), {
          [cur[0]]: cur[1].schema
        });
      }, {})
    }, config), {
      required: Object.entries(object).filter(([k, v]) => {
        return v.isRequired;
      }).map(([k]) => k)
    });
  }
}
const getEnumAsArray = (obj) => Object.values(obj);
const account_types = {
  live: "live",
  simulated: "simulated"
};
const account_types_enum$1 = getEnumAsArray(account_types);
const button_types = {
  button: "button",
  link: "link"
};
const button_types_enum$1 = getEnumAsArray(button_types);
const node_location = {
  body: "body",
  footer: "footer",
  header: "header"
};
const node_location_enum$1 = getEnumAsArray(node_location);
const platform = {
  android: "android",
  ios: "ios",
  web: "web"
};
const platform_enum$1 = getEnumAsArray(platform);
const idea_types = {
  buy: "buy",
  sell: "sell"
};
const idea_types_enum$1 = getEnumAsArray(idea_types);
const pro_or_con = {
  con: "con",
  pro: "pro"
};
const pro_or_con_enum$1 = getEnumAsArray(pro_or_con);
var enums = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  [Symbol.toStringTag]: "Module",
  getEnumAsArray,
  account_types,
  account_types_enum: account_types_enum$1,
  button_types,
  button_types_enum: button_types_enum$1,
  node_location,
  node_location_enum: node_location_enum$1,
  platform,
  platform_enum: platform_enum$1,
  idea_types,
  idea_types_enum: idea_types_enum$1,
  pro_or_con,
  pro_or_con_enum: pro_or_con_enum$1
});
const { account_types_enum, button_types_enum, node_location_enum, platform_enum, idea_types_enum, pro_or_con_enum } = enums;
const trackingEventsValidationSchema = {
  [trackingEvents.identify]: new ObjectType({}, { additionalProperties: true }).schema,
  [trackingEvents.accountTypeSelected]: new ObjectType({
    "account_type": new StringType(true, {
      enum: account_types_enum
    })
  }, { additionalProperties: false }).schema,
  [trackingEvents.bankLinkSkipped]: {},
  [trackingEvents.buttonClicked]: new ObjectType({
    "button_type": new StringType(true, {
      enum: button_types_enum
    }),
    "context": new StringType(true),
    "description": new StringType(true),
    "position": new StringType(true, {
      enum: node_location_enum
    }),
    "platform": new StringType(true, {
      enum: platform_enum
    })
  }, { "additionalProperties": false }).schema,
  [trackingEvents.feedLoaded]: new ObjectType({
    "item_count": new NumberType(true)
  }).schema,
  [trackingEvents.ideaViewed]: new ObjectType({
    "context": new StringType(true),
    "idea_type": new StringType(true, { enum: idea_types_enum }),
    "ticker_symbol": new StringType(true)
  }, { "additionalProperties": false }).schema,
  [trackingEvents.modalViewed]: new ObjectType({
    "context": new StringType(true),
    "is_error": new BooleanType(true),
    "message": new StringType(true),
    "modal_type": new StringType(true)
  }, { "additionalProperties": false }).schema,
  [trackingEvents.onboardingCompleted]: {},
  [trackingEvents.onboardingFundingSkipped]: {},
  [trackingEvents.pageViewed]: new ObjectType({
    "location": new StringType(true)
  }),
  [trackingEvents.proConViewed]: new ObjectType({
    "context": new StringType(true),
    "pro_or_con": new StringType(true, { enum: pro_or_con_enum }),
    "ticker_symbol": new StringType(true)
  }, { "additionalProperties": false }).schema,
  [trackingEvents.tabViewed]: new ObjectType({
    "context": new StringType(true),
    "platform": new StringType(true, { enum: platform_enum }),
    "position": new StringType(true, { enum: node_location_enum }),
    "description": new StringType(true)
  }, { "additionalProperties": false }).schema
};
class TrackedRouteStore {
  constructor() {
    __publicField(this, "addTrackedRoute", (trackedRoute) => {
      if (!trackedRoute._instance_trackedRoute)
        throw Error;
      this.trackedRoutes.push(trackedRoute);
    });
    __publicField(this, "getTrackingNameForWebappRoute", (urlPathName) => {
      const webappTrackedRoutesLookup = this._buildWebappTrackedRoutesLookup();
      if (webappTrackedRoutesLookup[urlPathName])
        return webappTrackedRoutesLookup[urlPathName].trackingRouteName;
      const _mutatable_dirs = urlPathName.split("/");
      if (_mutatable_dirs.length === 1)
        return null;
      _mutatable_dirs[_mutatable_dirs.length - 1] = "*";
      const wildCardPath = _mutatable_dirs.join("/");
      if (webappTrackedRoutesLookup[wildCardPath])
        return webappTrackedRoutesLookup[wildCardPath].trackingRouteName;
      if (_mutatable_dirs.length === 2)
        return null;
      _mutatable_dirs[_mutatable_dirs.length - 2] = "*";
      const doubleWildCardPath = _mutatable_dirs.join("/");
      if (webappTrackedRoutesLookup[doubleWildCardPath])
        return webappTrackedRoutesLookup[doubleWildCardPath].trackingRouteName;
      const modelDetailDirs = urlPathName.split("/");
      modelDetailDirs[_mutatable_dirs.length - 2] = "*";
      const modelDetailWildCardPath = modelDetailDirs.join("/");
      if (webappTrackedRoutesLookup[modelDetailWildCardPath])
        return webappTrackedRoutesLookup[modelDetailWildCardPath].trackingRouteName;
      return null;
    });
    __publicField(this, "getTrackingNameForAppRoute", (routeName) => {
      const matchingTrackedRoutes = this.trackedRoutes.filter((trackedRoute) => trackedRoute.appRouteName === routeName);
      if (matchingTrackedRoutes.length > 1) {
        console.warn("multiple matched routes");
      }
      const matchedRoute = matchingTrackedRoutes[0];
      return (matchedRoute == null ? void 0 : matchedRoute.trackingRouteName) || null;
    });
    __publicField(this, "getTrackingNameForRoute", (route, platform2) => {
      if (platform2 === PRODUCT_PLATFORMS.mobileApp) {
        return this.getTrackingNameForAppRoute(route);
      }
      if (platform2 === PRODUCT_PLATFORMS.webApp) {
        return this.getTrackingNameForWebappRoute(route);
      }
    });
    __publicField(this, "_buildWebappTrackedRoutesLookup", () => {
      const insertWildCard = (url) => {
        const dirs = url.split("/");
        const dirsWithWildCards = dirs.map((dir) => dir[0] === ":" ? "*" : dir);
        return dirsWithWildCards.join("/");
      };
      const routes = [];
      this.trackedRoutes.forEach((trackedRoute) => {
        const webappUrl = trackedRoute.urlPathName;
        if (webappUrl !== null) {
          routes.push(trackedRoute);
        }
      });
      const lookup = {};
      routes.forEach((trackedRoute) => {
        lookup[insertWildCard(trackedRoute.urlPathName)] = trackedRoute;
      });
      return lookup;
    });
    __publicField(this, "_scanForMissingWebappRoutes", () => {
      const missingRoutes = [];
      const { routes: allWebappTrackedRoutes, duplicatedRoutes } = this._getAllWebappTrackedRoutes();
      const allWebappRouteEntries = Object.entries(webappRoutes);
      allWebappRouteEntries.forEach((entry) => {
        const [routeName, route] = entry;
        if (!allWebappTrackedRoutes.includes(route)) {
          missingRoutes.push({ routeName, route });
        }
      });
      return {
        missingRoutes,
        duplicatedRoutes
      };
    });
    __publicField(this, "_scanForMissingAppRoutes", () => {
      const missingRoutes = [];
      const { routes: allAppTrackedRoutes, duplicatedRoutes } = this._getAllAppTrackedRoutes();
      const allAppRouteEntries = Object.entries(appRoutes);
      allAppRouteEntries.forEach((entry) => {
        const [routeName, route] = entry;
        if (!allAppTrackedRoutes.includes(route)) {
          missingRoutes.push({ routeName, route });
        }
      });
      return {
        missingRoutes,
        duplicatedRoutes
      };
    });
    __publicField(this, "_getAllAppTrackedRoutes", () => {
      const routes = [];
      const duplicatedRoutes = [];
      this.trackedRoutes.forEach((trackedRoute) => {
        const webappUrl = trackedRoute.appRouteName;
        if (webappUrl !== null) {
          if (routes.includes(webappUrl)) {
            duplicatedRoutes.push(webappUrl);
          } else {
            routes.push(webappUrl);
          }
        }
      });
      return {
        routes,
        duplicatedRoutes
      };
    });
    __publicField(this, "_getAllWebappTrackedRoutes", () => {
      const routes = [];
      const duplicatedRoutes = [];
      this.trackedRoutes.forEach((trackedRoute) => {
        const webappUrl = trackedRoute.urlPathName;
        if (webappUrl !== null) {
          if (routes.includes(webappUrl)) {
            duplicatedRoutes.push(webappUrl);
          } else {
            routes.push(webappUrl);
          }
        }
      });
      return {
        routes,
        duplicatedRoutes
      };
    });
    this.trackedRoutes = [];
  }
}
const _trackedRouteStore = new TrackedRouteStore();
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.security, appRoutes.SECURITY_PAGE, "Security"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.thoughtWithSlug, null, "Thought"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.thought, null, "Thought"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.VIEWPOINT_EXPLORE, "View All Viewpoints"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.featuredViewpoints, appRoutes.VIEWPOINT_WELCOME, "Featured Viewpoints"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.manageViewpoints, appRoutes.VIEWPOINT_SUBSCRIPTION_MANAGEMENT, "Viewpoints Management"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.viewpointProfile, appRoutes.VIEWPOINT_PROFILE, "Viewpoint Profile"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.bdTerms, null, "BD Terms"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.socureDocSubmission, appRoutes.LIVE_ACCOUNT_FURTHER_DOCUMENTATION_ENTRY, "Socure Document Upload"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ONBOARDING_CONTACT_RESUBMIT, "Address Resubmit"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.resubmitAddress, appRoutes.CONTACT_RESUBMIT, "Address Resubmit"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.uploadDocuments, null, "Upload Documents"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.onboardingUploadDocuments, null, "Upload Documents (Onboarding)"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.liveAccountSubmitConfirmation, appRoutes.LIVE_ACCOUNT_SUBMITTED, "Live Account Submit Confirmation"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.onboardingAlmostThere, appRoutes.ONBOARDING_ALMOST_THERE, "Onboarding Almost There"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ONBOARDING_FUNDING, "Funding"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ENTER_NAME, "Enter Name"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ENTER_ADDRESS, "Enter Contact Address"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ENTER_PHONE, "Enter Phone Number"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.agreements, appRoutes.AGREEMENTS, "Agreements"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.disclosures, appRoutes.DISCLOSURES, "Disclosures"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.identity, appRoutes.IDENTITY, "Identity"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ENTER_DATE_OF_BIRTH, "Enter Date of Birth"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.contact, appRoutes.CONTACT, "Contact"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.investorProfile, appRoutes.INVESTOR_PROFILE, "Investor Profile"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.onboardResumeProgress, appRoutes.START_ONBOARDING, "Resume Onboarding"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.selectAccountType, appRoutes.ONBOARDING_ACCOUNT_SELECTION, "Account Type Selection"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.investingExperience, appRoutes.LT_INVESTING_EXPERIENCE, "Investing Experience Select"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.PT_INVESTING_EXPERIENCE, "Investing Experience Select"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.LT_DEFAULT_EXPECTED_RETURN, "Default Expected Return Select"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.PT_DEFAULT_EXPECTED_RETURN, "Default Expected Return Select"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.START_PAPER_TRADING_SIGN_UP, "Start Paper Trading Signup"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CLOSED_ACCOUNT, "Closed Account"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.SEARCH, "Search"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.EXPLORE, "Explore"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.FILTERED_SECURITY_LIST, "Filtered Security List"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.SECURITY_LIVE_PRICING, "Security Live Pricing"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CREATE_ORDER, "Create Trade"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CREATE_THOUGHT, "Create Thought"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CONTACTS_IMPORT, "Contacts Import"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.COMMUNITY_SEARCH, "Community Search"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CONNECTIONS_LIST, "Connections List"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ACCOUNT, "Account Settings"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.USER_SETTINGS, "User Settings"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.legal, appRoutes.LEGAL, "Legal Links"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.SUPPORT, "Support"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CREATE_SUPPORT_REQUEST, "Create Support Request"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CHANGE_EMAIL, "Change Email"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CHANGE_PASSWORD, "Change Password"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.UPDATE_PREFERENCES, "Update Preferences"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.UPDATE_LIVE_ACCOUNT, "Update Live Account"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.linkBank, appRoutes.LINK_BANK_ACCOUNT, "Link Bank Account Method Selection"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.LINK_BANK_ACCOUNT_PLAID, "Link Bank Account Plaid"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.LINK_BANK_ACCOUNT_MICRO, "Link Bank Account Micro"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.VERIFY_BANK_ACCOUNT, "Verify Bank Account"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.MANAGE_BANK_ACCOUNTS, "Manage Bank Account"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.INTERESTS_SELECTION, "Interests Selection"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.INTEREST_WATCHLIST, "Interests Watchlist"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.PROMPT_PICK_OWN_STOCKS, "Pick Own Stocks Prompt"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.CTL_RESPONSE, "CTL Response"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.TRENDING_STOCKS, "Trending Stocks"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.GETTING_REWARDS_INTERSTITIAL, "Fetching Rewards"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.UPCOMING_EARNINGS, "Upcoming Earnings"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.THOUGHTS_LIST, "Thought List"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.SITE_DISCLOSURES, "Site Disclosures"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.PUSH_NOTIFICATION_DETAIL, "Push Notification Detail"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.dashboard, null, "Webapp Dashboard"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.portfolio, appRoutes.DASHBOARD, "Portfolio"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.ACTIVITY_FEED, "Activity Feed"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.trade, appRoutes.TRADE, "Trade"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.portfolioFunding, appRoutes.FUNDING, "Funding"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.funding, null, "Funding"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.withdrawFunds, appRoutes.FUNDING_WITHDRAWAL, "Funding Transfer - Withdrawal"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.depositFunds, appRoutes.FUNDING_DEPOSIT, "Funding Transfer - Deposit"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.PAPER_FUNDING_TRANSFERS, "Funding Transfer"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.LT_FUNDING_DEPOSIT, "Funding Transfer (Onboarding)"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.myIdeas, null, "My Ideas"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.connections, appRoutes.COMMUNITY, "Community"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.portfolioHistory, appRoutes.ACCOUNT_TRANSACTION_HISTORY, "Account History"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.eDocuments, null, "E Documents"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.usersSignup, null, "Signup"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.SPLASH, "Splash"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.login, appRoutes.SPLASH_SIGN_IN, "Login"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.signup, appRoutes.SPLASH_SIGN_UP, "Signup"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.editProfile, appRoutes.EDIT_USER_PROFILE, "Edit User Profile"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.privacySettings, appRoutes.PRIVACY_SETTING_SELECT, "Select Privacy Setting"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.userProfile, appRoutes.USER_PROFILE, "User Profile"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.thoughtLeaders, null, "Thought Leaders"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.ask, appRoutes.CREATE_SHARE, "Create Ask"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.inviteFriends, appRoutes.REFERRAL_ACTIONS, "All Referral Actions"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.rewards, appRoutes.REWARDS, "Rewards"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.rewardRedemption, appRoutes.REDEEM_REWARDS, "Redeem Rewards"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.LT_REDEEM_REWARDS, "Redeem Rewards"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.learnAndEarn, appRoutes.LEARN, "Learn and Earn Lesson"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.learnAndEarnProgress, appRoutes.LEARN_LESSONS_COMPLETED, "Learn and Earn Progress"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.learnAndEarnMoreInfo, appRoutes.LEARN_DISCLAIMERS, "Learn and Earn More Info"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.LEARN_REWARD_EARNED, "Learn and Earn Reward Earned"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.subscriptions, null, "Subscriptions Payment"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.membership, null, "Membership Payment"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.inviteFriendsFromPlatform, null, "Invite Friends From Platform"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.inviteFriendsFromPlatformSuccess, null, "Invite Friends From Platform - Success"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.inviteFriendsFromPlatformFailure, null, "Invite Friends From Platform - Failure"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.inviteContactsFailure, null, "Contact Import Failure"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.faq, null, "FAQ"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.marginDisclosure, null, "Margin Disclosure"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.privacyPolicy, null, "Privacy Policy"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.tos, null, "Terms of Service"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.tosNvstrFinancial, null, "Terms of Service - Nvstr Financial"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.tosNvstrTechnologies, null, "Terms of Service - Nvstr Technologies"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.referralRules, null, "Referral Rules"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.viewpointGuidelines, null, "Viewpoint Contributor Guidelines"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.shareLink, null, "Share Link Visited (Already Logged In)"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.DEV_TOOLS, "Dev Tools"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.DEV, "Dev"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.AUTHED_APP_INITIALIZATION, "App Loading"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.START_LIVE_TRADING_SIGN_UP, "Start Live Trading"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.PROMO_REDEMPTION_SUCCESS, "Promo Redemption Success"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(null, appRoutes.VIEWPOINT_SUBSCRIPTION_SUCCESS, "Viewpoint Subscription Success"));
_trackedRouteStore.addTrackedRoute(new TrackedRoute(webappRoutes.finishAccountSetup, appRoutes.FINISH_ACCOUNT_SETUP, "Finish Account Setup"));
const trackedRouteStore = _trackedRouteStore;
const parseQueryString = (query) => {
  if (!query || query.length === 0) {
    return {};
  }
  const keyValues = query.split("?")[1];
  const keyValuesArray = keyValues.split("&");
  const parsedQuery = {};
  keyValuesArray.forEach((string) => {
    const key = string.split("=")[0];
    const value = string.split("=")[1];
    parsedQuery[key] = decodeURI(value);
  });
  return parsedQuery;
};
const createQueryString = (query, options) => {
  const queryArray = [];
  const keys = Object.keys(query);
  if (keys.length === 0 || !keys) {
    return "";
  }
  keys.forEach((key) => {
    const value = query[key];
    queryArray.push(`${key}=${value}`);
  });
  const queryPrefix = options && options.isAppending ? "&" : "?";
  const queryString = queryPrefix + queryArray.join("&");
  return queryString;
};
export { PRODUCT_PLATFORMS, appRoutes, createQueryString, enums, parseQueryString, trackedRouteStore, trackingEvents, trackingEventsValidationSchema, webappRoutes };
