import styled, { css, keyframes, withTheme } from 'styled-components';
import React from 'react';
const LAYOUT = {
  top: 'flex-start',
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
  bottom: 'flex-end',
};
const Padding = css`
  padding: ${(props) => props.padding};
`;
const Margin = css`
  margin: ${(props) => props.margin};
`;
const Zindex = css`
  z-index: ${(props) => (props.zIndex ? props.zIndex : 1)};
`;
const Position = css`
  position: ${(props) => props.position};
`;
const Overflow = css`
  overflow: ${(props) => props.overflow};
`;
const Border = css`
  border: ${(props) => props.border};
`;
const BorderStyle = css`
  border-style: ${(props) => props.borderStyle};
`;
const WordBreak = css`
  word-break: ${(props) => props.wordBreak};
`;
const Transition = css`
  transition: ${(props) => props.transition};
`;
const Cursor = css`
  cursor: ${(props) => props.cursor};
`;
const MaxWidth = css`
  max-width: ${(props) => props.maxWidth};
`;
const MaxHeight = css`
  max-height: ${(props) => props.maxHeight};
`;
const Shrink = css`
  flex-shrink: ${(props) => props.shrink};
`;
const Wrap = css`
  flex-wrap: ${(props) => props.wrap};
`;
const LineHeight = css`
  line-height: ${(props) => props.lineHeight || '160%'};
`;
const LetterSpacing = css`
  letter-spacing: ${(props) => props.letterSpacing};
`;
const FontSize = css`
  font-size: ${(props) => props.fontSize};
`;
const Color = css`
  color: ${(props) => props.color};
`;
const FontWeight = css`
  font-weight: ${(props) => props.fontWeight};
`;
const TextAlign = css`
  text-align: ${(props) => props.align};
`;
const TextTransform = css`
  text-transform: ${(props) => props.textTransform};
`;
const FontStyle = css`
  font-style: ${(props) => props.fontStyle};
`;
const FontFamily = css`
  font-family: ${(props) => props.fontFamily};
`;
const UserSelect = css`
  user-select: ${(props) => props.userSelect};
`;
const Background = css`
  background: ${(props) => props.background};
`;
const Left = css`
  left: ${(props) => props.left};
`;
const Top = css`
  top: ${(props) => props.top};
`;
const Opacity = css`
  opacity: ${(props) => props.opacity};
`;
const Width$1 = css`
  width: ${(props) => props.width};
`;
const Height$1 = css`
  height: ${(props) => (props.height ? props.height : 'auto')};
`;
const HorizontalAlign$1 = css`
  justify-content: ${(props) => (props.hAlign && LAYOUT[props.hAlign] ? LAYOUT[props.hAlign] : LAYOUT.left)};
`;
const VerticalAlign$1 = css`
  align-items: ${(props) => (props.vAlign && LAYOUT[props.vAlign] ? LAYOUT[props.vAlign] : 'stretch')};
`;
const Row = styled('div')`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  ${(props) => (props.shrink ? Shrink : '')};
  ${(props) => (props.wrap ? Wrap : '')};

  ${(props) => (props.width ? Width$1 : '')};
  ${(props) => (props.height ? Height$1 : '')};

  ${(props) => (props.hAlign ? HorizontalAlign$1 : '')};
  ${(props) => (props.vAlign ? VerticalAlign$1 : '')};

  ${(props) => (props.padding ? Padding : '')};
  ${(props) => (props.margin ? Margin : '')};
  ${(props) => (props.zIndex ? Zindex : '')};
  ${(props) => (props.position ? Position : '')};
  ${(props) => (props.overflow ? Overflow : '')};
  ${(props) => (props.border ? Border : '')};
  ${(props) => (props.borderStyle ? BorderStyle : '')};
  ${(props) => (props.wordbreak ? WordBreak : '')};
  ${(props) => (props.maxWidth ? MaxWidth : '')};
  ${(props) => (props.maxHeight ? MaxHeight : '')};
  ${(props) => (props.transition ? Transition : '')};
  ${(props) => (props.cursor ? Cursor : '')};
  ${(props) => (props.background ? Background : '')};

  ${(props) => (props.left ? Left : '')};
  ${(props) => (props.top ? Top : '')};
`;
const Width = css`
  flex-basis: ${(props) => props.width};
`;
const Height = css`
  height: ${(props) => props.height};
`;
const HorizontalAlign = css`
  align-items: ${(props) => (props.hAlign && LAYOUT[props.hAlign] ? LAYOUT[props.hAlign] : 'stretch')};
`;
const VerticalAlign = css`
  justify-content: ${(props) => (props.vAlign && LAYOUT[props.vAlign] ? LAYOUT[props.vAlign] : 'stretch')};
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${(props) => (props.shrink ? Shrink : '')};
  ${(props) => (props.wrap ? Wrap : '')};

  ${(props) => (props.width ? Width : '')};
  ${(props) => (props.height ? Height : '')};

  ${(props) => (props.hAlign ? HorizontalAlign : '')};
  ${(props) => (props.vAlign ? VerticalAlign : '')};

  ${(props) => (props.padding ? Padding : '')};
  ${(props) => (props.margin ? Margin : '')};
  ${(props) => (props.zIndex ? Zindex : '')};
  ${(props) => (props.position ? Position : '')};
  ${(props) => (props.overflow ? Overflow : '')};
  ${(props) => (props.border ? Border : '')};
  ${(props) => (props.borderStyle ? BorderStyle : '')};
  ${(props) => (props.wordbreak ? WordBreak : '')};
  ${(props) => (props.maxWidth ? MaxWidth : '')};
  ${(props) => (props.maxHeight ? MaxHeight : '')};
  ${(props) => (props.transition ? Transition : '')};
  ${(props) => (props.cursor ? Cursor : '')};
  ${(props) => (props.background ? Background : '')};
  ${(props) => (props.left ? Left : '')};
  ${(props) => (props.top ? Top : '')};
`;
const Place = styled('div')`
  position: relative;
  top: ${(props) => props.top || 'auto'};
  bottom: ${(props) => props.bottom || 'auto'};
  right: ${(props) => props.right || 'auto'};
  left: ${(props) => props.left || 'auto'};
  display: ${(props) => props.display || 'block'};
  height: ${(props) => props.height || 'auto'};
  width: ${(props) => props.width || 'auto'};
  ${(props) => (props.padding ? Padding : '')};
  ${(props) => (props.margin ? Margin : '')};
  ${(props) => (props.zIndex ? Zindex : '')};
  ${(props) => (props.position ? Position : '')};
  ${(props) => (props.overflow ? Overflow : '')};
  ${(props) => (props.border ? Border : '')};
  ${(props) => (props.wordbreak ? WordBreak : '')};
  ${(props) => (props.maxWidth ? MaxWidth : '')};
  ${(props) => (props.maxHeight ? MaxHeight : '')};
  ${(props) => (props.transition ? Transition : '')};
  ${(props) => (props.cursor ? Cursor : '')};
`;
const colorPalette = {
  primary: {
    charcoal: '#2a2b2b',
    oak: '#F7F4F2',
  },
  secondary: {
    yellow: '#fcfe7d',
    navy: '#211e2c',
    black: '#000',
    white: '#fff',
    cyan: '#9ffbf5',
    purple: '#6d56fb',
    lavender: '#dcdbff',
    red: '#f5665d',
    green: '#7ecb00',
    darkRed: '#bc332c',
    darkGreen: '#5a7a00',
    charcoalHighlight: 'rgba(42, 43, 43, 0.05)',
    oakHighlight: 'rgba(247, 244, 242, 0.05)',
  },
};
const Wrapper$4 = styled.div`
  path {
    fill: ${(props) => props.color || colorPalette.primary.charcoal};
  }
`;
const Close = ({ width, color }) => {
  const ratio = 13 / 12;
  const height = width / ratio;
  return /* @__PURE__ */ React.createElement(
    Wrapper$4,
    {
      color,
    },
    /* @__PURE__ */ React.createElement(
      'svg',
      {
        width,
        height,
        viewBox: '0 0 13 12',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      /* @__PURE__ */ React.createElement('path', {
        d: 'M12.3327 1.34163L11.1577 0.166626L6.49935 4.82496L1.84102 0.166626L0.666016 1.34163L5.32435 5.99996L0.666016 10.6583L1.84102 11.8333L6.49935 7.17496L11.1577 11.8333L12.3327 10.6583L7.67435 5.99996L12.3327 1.34163Z',
      })
    )
  );
};
const animation = keyframes`
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;
const ModalStyle = styled(Column).attrs(() => ({
  hAlign: 'center',
}))`
  width: 570px;
  height: 500px;

  position: relative;
  top: 50px;
  left: calc(50vw - 285px);

  border-radius: 2px;
  background: ${({ isDarkTheme }) => (isDarkTheme ? colorPalette.primary.charcoal : colorPalette.primary.oak)};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 101;
  overflow: auto;

  -webkit-animation: ${({ isAnimated }) =>
    isAnimated
      ? css`
          ${animation} 150ms ease-in
        `
      : 'unset'};
  -moz-animation: ${({ isAnimated }) =>
    isAnimated
      ? css`
          ${animation} 150ms ease-in
        `
      : 'unset'};
  -o-animation: ${({ isAnimated }) =>
    isAnimated
      ? css`
          ${animation} 150ms ease-in
        `
      : 'unset'};
  animation: ${({ isAnimated }) =>
    isAnimated
      ? css`
          ${animation} 150ms ease-in
        `
      : 'unset'};

  @media (max-width: 625px) {
    width: 90vw;
    left: 5vw;
    top: 2vw;
  }
`;
const BackgroundStyle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(circle at left top, rgba(252, 255, 125, 1) 0%, rgba(255, 255, 255, 1) 45%);
  z-index: -1;
  border-radius: 2px;
`;
const DismissButton = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
`;
const Modal = ({ children, isAnimated, onDismiss }) => {
  return /* @__PURE__ */ React.createElement(
    React.Fragment,
    null,
    /* @__PURE__ */ React.createElement(Overlay, {
      isAnimated,
      onDismiss,
    }),
    /* @__PURE__ */ React.createElement(
      ModalStyle,
      {
        isAnimated,
      },
      children,
      /* @__PURE__ */ React.createElement(
        DismissButton,
        {
          onClick: onDismiss,
        },
        /* @__PURE__ */ React.createElement(Close, {
          width: 16,
        })
      ),
      /* @__PURE__ */ React.createElement(BackgroundStyle, null)
    )
  );
};
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 0.9;
  }
`;
const Wrapper$3 = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  opacity: 0.9;
  background-color: ${({ isDarkTheme }) => (isDarkTheme ? colorPalette.primary.charcoal : colorPalette.primary.oak)};

  -webkit-animation: ${({ isAnimated }) =>
    isAnimated
      ? css`
          ${fadeIn} 300ms
        `
      : 'unset'};
  -moz-animation: ${({ isAnimated }) =>
    isAnimated
      ? css`
          ${fadeIn} 300ms
        `
      : 'unset'};
  -o-animation: ${({ isAnimated }) =>
    isAnimated
      ? css`
          ${fadeIn} 300ms
        `
      : 'unset'};
  animation: ${({ isAnimated }) =>
    isAnimated
      ? css`
          ${fadeIn} 300ms
        `
      : 'unset'};
`;
const Overlay = ({ isAnimated, onDismiss }) => {
  return /* @__PURE__ */ React.createElement(Wrapper$3, {
    isAnimated,
    onClick: onDismiss,
  });
};
const Text = styled('div')`
  box-sizing: border-box;
  line-height: 160%;
  ${(props) => (props.lineHeight ? LineHeight : '')};
  ${(props) => (props.letterSpacing ? LetterSpacing : '')};
  ${(props) => (props.fontSize ? FontSize : '')};
  ${(props) => (props.fontWeight ? FontWeight : '')};
  ${(props) => (props.fontFamily ? FontFamily : '')};
  ${(props) => (props.fontStyle ? FontStyle : '')};
  ${(props) => (props.color ? Color : '')};
  ${(props) => (props.opacity ? Opacity : '')};

  ${(props) => (props.textAlign ? TextAlign : '')};
  ${(props) => (props.textTransform ? TextTransform : '')};
  ${(props) => (props.userSelect ? UserSelect : '')};

  ${(props) => (props.padding ? Padding : '')};
  ${(props) => (props.margin ? Margin : '')};
  ${(props) => (props.cursor ? Cursor : '')};
`;
const smallSize = css`
  svg {
    width: 150px;
  }
`;
const Wrapper$2 = styled.div`
  ${(props) => (props.isSmall ? smallSize : '')};
  g {
    fill: ${(props) => (props.isDarkMode ? '#fff' : '#222')};
  }
`;
function LogoFull({ isDarkMode, isSmall }) {
  return /* @__PURE__ */ React.createElement(
    Wrapper$2,
    {
      isDarkMode,
      isSmall,
    },
    /* @__PURE__ */ React.createElement(
      'svg',
      {
        width: 184,
        height: 21,
        viewBox: '0 0 184 21',
        className: 'full-logo-tornado',
      },
      /* @__PURE__ */ React.createElement(
        'g',
        {
          fill: '#1F1F1F',
          fillRule: 'nonzero',
        },
        /* @__PURE__ */ React.createElement('path', {
          d: 'M45.674 4.201v16.542h-4.55V4.201h-8.723V0h21.982v4.201h-8.71zm29.118 7.304v.153c0 5.732-3.2 9.21-10.838 9.21-7.638 0-10.865-3.492-10.865-9.21v-.153c0-5.731 3.227-9.21 10.865-9.21 7.624 0 10.838 3.492 10.838 9.21zm-4.591.126v-.098c0-3.812-1.934-5.133-6.26-5.133-4.327 0-6.261 1.321-6.261 5.133v.098c0 3.812 1.934 5.133 6.26 5.133 4.327.014 6.26-1.307 6.26-5.133zm18.587-9.335c.64 0 .946.027 1.35.125v4.535c-3.103-.71-8.404-.556-8.404 2.337v11.45h-4.577V2.421h4.577v6.024c0-3.144 1.837-6.15 7.054-6.15zm8.25 5.954c0-3.005 1.836-5.954 7.331-5.954 5.273 0 8.348 2.518 8.348 7.373v11.074h-4.578V10.24c0-2.393-1.502-3.965-5.467-3.965-2.727 0-5.62.557-5.62 2.825v11.658h-4.578V2.421h4.577V8.25h-.014zm23.205.96h-4.633v-.098c0-4.354 3.59-6.816 9.642-6.816 6.775 0 9.766 2.03 9.766 6.942v11.505h-4.549v-4.535c0 1.878-1.962 4.66-7.61 4.66-5.217 0-8.097-1.224-8.097-4.813v-.223c0-5.398 6.97-5.273 15.707-6.51v-.029c0-2.142-1.113-3.158-5.398-3.158-3.534 0-4.842 1.016-4.842 3.005v.07h.014zm3.13 8.653c3.618 0 7.096-.375 7.096-2.462v-2.616c-8.473.946-11.116 1.405-11.116 3.033v.098c0 1.21.974 1.947 4.02 1.947zM154.606 0h4.55v20.743h-4.55V15.61c0 2.337-1.934 5.272-7.665 5.272-6.776 0-9.516-3.687-9.516-9.238v-.097c0-5.55 2.754-9.238 9.516-9.238 5.731 0 7.665 2.922 7.665 5.273V0zm-6.218 6.135c-4.272 0-6.386 1.35-6.386 5.398v.098c0 4.048 2.114 5.398 6.386 5.398 3.255 0 6.204-.46 6.204-2.296V8.417c.014-1.823-2.949-2.282-6.204-2.282zm34.85 5.37v.153c0 5.732-3.2 9.21-10.838 9.21-7.638 0-10.865-3.492-10.865-9.21v-.153c0-5.731 3.227-9.21 10.865-9.21 7.624 0 10.838 3.492 10.838 9.21zm-4.591.126v-.098c0-3.812-1.934-5.133-6.261-5.133s-6.26 1.321-6.26 5.133v.098c0 3.812 1.933 5.133 6.26 5.133 4.327.014 6.261-1.307 6.261-5.133zM14.872 20.743c-.139-.556-.626-1.071-1.21-1.725-1.586-1.753-3.604-6.233 1.655-9.057C19.964 7.471 24.792 4.438 26.628 0H11.756c.139.556.626 1.071 1.21 1.725 1.586 1.753 3.604 6.233-1.655 9.057C6.664 13.272 1.822 16.305 0 20.743h14.872z',
        })
      )
    )
  );
}
const Wrapper$1 = styled.div`
  g {
    fill: ${(props) => (props.isDarkMode ? '#fff' : 'black')};
  }

  @media (prefers-color-scheme: dark) {
    g {
      fill: #fff;
    }
  }
`;
function LogoShort({ isDarkMode, height, width }) {
  return /* @__PURE__ */ React.createElement(
    Wrapper$1,
    {
      isDarkMode,
    },
    /* @__PURE__ */ React.createElement(
      'svg',
      {
        width,
        height,
        viewBox: `0 0 59 51`,
      },
      /* @__PURE__ */ React.createElement(
        'g',
        null,
        /* @__PURE__ */ React.createElement('path', {
          d: 'M31.3589 51C31.0356 49.6297 29.8906 48.3508 28.5032 46.7457C24.7719 42.4522 20.0169 31.4378 32.4096 24.4821C43.3205 18.3485 54.6895 10.8969 59 0L27.6546 0C27.9779 1.37027 29.1228 2.64918 30.5103 4.25435C34.2416 8.54785 38.9966 19.5622 26.6039 26.5179C15.6795 32.6515 4.3105 40.1031 0 51L31.3589 51Z',
        })
      )
    )
  );
}
const Wrapper = styled.div`
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  overflow: visible;
  margin: 12px 24px;
  width: 60px;
  height: 60px;
  & > svg {
    width: 45px;
    height: 45px;
  }

  & > svg {
    display: block;
    overflow: visible;
    animation-name: spin-cycle;
  }

  @keyframes spin-cycle {
    from {
      fill: ${(props) => props.theme.secondary.purple};
      transform: scale(1);
    }
    to {
      fill: rgba(255, 255, 255, 0);
      transform: scale(1);
    }
  }

  & > svg > * {
    transform: translate3d(0, 0, 0);
    transform-origin: center;
    animation-iteration-count: infinite;
    fill: rgba(255, 255, 255, 0.2);
    animation-name: spin-cycle;
    animation-duration: 0.8s;
  }
  & > svg > *:nth-of-type(8) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }
  & > svg > *:nth-of-type(7) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }
  & > svg > *:nth-of-type(6) {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  & > svg > *:nth-of-type(5) {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  & > svg > *:nth-of-type(4) {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }
  & > svg > *:nth-of-type(3) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  
  & > svg > *:nth-of-type(2) {
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
  }
  & > svg > *:nth-of-type(1) {
    animation-delay: 0.8s;
  }
`;
function LoadingSpinner({ isDarkMode }) {
  return /* @__PURE__ */ React.createElement(
    Wrapper,
    {
      isDarkMode,
    },
    /* @__PURE__ */ React.createElement(
      'svg',
      {
        viewBox: '156 -189 512 512',
      },
      /* @__PURE__ */ React.createElement('path', {
        d: 'M636 99h-64c-17.7 0-32-14.3-32-32s14.3-32 32-32h64c17.7 0 32 14.3 32 32S653.7 99 636 99z',
      }),
      /* @__PURE__ */ React.createElement('path', {
        d: 'M547.8-23.5C535.2-11 515-11 502.5-23.5s-12.5-32.8 0-45.2l45.2-45.2c12.5-12.5 32.8-12.5 45.2 0s12.5 32.8 0 45.2L547.8-23.5z',
      }),
      /* @__PURE__ */ React.createElement('path', {
        d: 'M412-61c-17.7 0-32-14.3-32-32v-64c0-17.7 14.3-32 32-32s32 14.3 32 32v64C444-75.3 429.7-61 412-61z',
      }),
      /* @__PURE__ */ React.createElement('path', {
        d: 'M276.2-23.5L231-68.8c-12.5-12.5-12.5-32.8 0-45.2s32.8-12.5 45.2 0l45.2 45.2c12.5 12.5 12.5 32.8 0 45.2S288.8-11 276.2-23.5z',
      }),
      /* @__PURE__ */ React.createElement('path', {
        d: 'M284 67c0 17.7-14.3 32-32 32h-64c-17.7 0-32-14.3-32-32s14.3-32 32-32h64C269.7 35 284 49.3 284 67z',
      }),
      /* @__PURE__ */ React.createElement('path', {
        d: 'M276.2 248c-12.5 12.5-32.8 12.5-45.2 0 -12.5-12.5-12.5-32.8 0-45.2l45.2-45.2c12.5-12.5 32.8-12.5 45.2 0s12.5 32.8 0 45.2L276.2 248z',
      }),
      /* @__PURE__ */ React.createElement('path', {
        d: 'M412 323c-17.7 0-32-14.3-32-32v-64c0-17.7 14.3-32 32-32s32 14.3 32 32v64C444 308.7 429.7 323 412 323z',
      }),
      /* @__PURE__ */ React.createElement('path', {
        d: 'M547.8 157.5l45.2 45.2c12.5 12.5 12.5 32.8 0 45.2 -12.5 12.5-32.8 12.5-45.2 0l-45.2-45.2c-12.5-12.5-12.5-32.8 0-45.2S535.2 145 547.8 157.5z',
      })
    )
  );
}
const BaseButton = styled(Column).attrs(() => ({
  hAlign: 'center',
  vAlign: 'center',
}))`
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  width: ${(props) => props.width || '200px'};
  height: ${(props) => props.height || '64px'};
  background: ${(props) => props.background || ''};
  border: 2px solid ${(props) => props.borderColor || props.background};
  transition: background-color 300ms, color 300ms;
  div {
    color: ${(props) => props.color};
  }
  &:hover {
    background: ${(props) => props.hoverBackground || ''};
    border: 2px solid ${(props) => props.borderHoverColor || props.background};
    div {
      color: ${(props) => props.hoverColor};
    }
  }
`;
const ButtonText = ({ children }) =>
  /* @__PURE__ */ React.createElement(
    Text,
    {
      fontSize: '16px',
    },
    children
  );
var ButtonText$1 = withTheme(ButtonText);
const Button1 = ({ theme, children, width, onClick }) =>
  /* @__PURE__ */ React.createElement(
    BaseButton,
    {
      background: theme.secondary.purple,
      hoverBackground: theme.secondary.white,
      borderHoverColor: theme.secondary.purple,
      color: theme.secondary.white,
      hoverColor: theme.primary.charcoal,
      width,
      onClick,
    },
    /* @__PURE__ */ React.createElement(ButtonText$1, null, children)
  );
var Button1$1 = withTheme(Button1);
const Button2 = ({ theme, children, width, onClick, isDarkMode }) =>
  /* @__PURE__ */ React.createElement(
    BaseButton,
    {
      background: isDarkMode ? theme.secondary.white : theme.primary.charcoal,
      hoverBackground: theme.secondary.white,
      borderHoverColor: theme.primary.charcoal,
      color: isDarkMode ? theme.primary.charcoal : theme.secondary.white,
      hoverColor: theme.primary.charcoal,
      width,
      onClick,
    },
    /* @__PURE__ */ React.createElement(ButtonText$1, null, children)
  );
var Button2$1 = withTheme(Button2);
const Button3 = ({ theme, children, width, onClick }) =>
  /* @__PURE__ */ React.createElement(
    BaseButton,
    {
      background: 'transparent',
      hoverBackground: theme.primary.charcoal,
      borderHoverColor: theme.primary.charcoal,
      color: theme.primary.charcoal,
      hoverColor: theme.secondary.white,
      borderColor: theme.primary.charcoal,
      width,
      onClick,
    },
    /* @__PURE__ */ React.createElement(ButtonText$1, null, children)
  );
var Button3$1 = withTheme(Button3);
const Button4 = ({ theme, children }) =>
  /* @__PURE__ */ React.createElement(
    BaseButton,
    {
      background: theme.primary.charcoal,
      hoverBackground: theme.secondary.white,
      borderHoverColor: theme.primary.charcoal,
      color: theme.secondary.white,
      hoverColor: theme.primary.charcoal,
      width: '120px',
      height: '50px',
    },
    /* @__PURE__ */ React.createElement(ButtonText$1, null, children)
  );
var Button4$1 = withTheme(Button4);
const ADJUST_EVENT_NAMES = {
  VIEW_SECURITY_SCREEN: 'lxfonv',
  VIEW_TRADE_SCREEN: 'sv1w0c',
  COMPLETED_LIVE_SIGNUP: 'llm728',
  COMPLETED_PAPER_SIGNUP: 'ohhbcj',
  PLACED_LIVE_TRADE: '52bu6x',
  ADDED_IDEA: 's2pxm9',
  POSTED_THOUGHT: '4w1su4',
};
const _convertEventIdToName = (id) => {
  const lookup = {};
  const keys = Object.keys(ADJUST_EVENT_NAMES);
  keys.forEach((k) => (lookup[ADJUST_EVENT_NAMES[k]] = k));
  return lookup[id];
};
let logEvents = false;
const setShouldLogEvents = (v) => (logEvents = !!v);
let Adjust = null;
const setAdjustClass = (v) => (Adjust = v);
const initializeAdjust = (Adjust2, appToken, adjustEnvironment, config = {}) => {
  setAdjustClass(Adjust2);
  const { logEvents: logEvents2 } = config;
  setShouldLogEvents(logEvents2);
  if (!appToken) {
    appToken = 'xgnikgxey680';
    console.warn('Falling back to backup adjust token');
  }
  if (!adjustEnvironment) {
    adjustEnvironment = 'sandbox';
  }
  if (logEvents2) {
    console.log('Adjust Init', {
      appToken,
      environment: adjustEnvironment,
    });
  }
  Adjust2.initSdk({
    appToken,
    environment: adjustEnvironment,
  });
};
const addAdjustSessionCallbackParams = (properties = []) => {
  if (properties.length === 0) return;
  Adjust.addGlobalCallbackParameters(properties);
};
const logAdjustEvent = (eventId, callbackParams = []) => {
  if (logEvents) {
    console.log('Adjust Event Sent', {
      eventId,
      name: _convertEventIdToName(eventId),
      callbackParams,
    });
  }
  Adjust.trackEvent({
    eventToken: eventId,
    callbackParams,
  });
};
const sendAdjustRevenueEvent = (eventId, value, callbackParams = []) => {
  Adjust.trackEvent({
    eventToken: eventId,
    revenue: value,
    currency: 'USD',
    callbackParams,
  });
};
const useScript = (src, onBeforeLoad) => {
  const [status, setStatus] = React.useState(src ? 'loading' : 'idle');
  React.useEffect(() => {
    if (!src) {
      setStatus('idle');
      return;
    }
    let script = document.querySelector(`script[src="${src}"]`);
    if (!script) {
      onBeforeLoad && onBeforeLoad();
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.setAttribute('data-status', 'loading');
      document.body.appendChild(script);
      const setAttributeFromEvent = (event) => {
        script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
      };
      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute('data-status'));
    }
    const setStateFromEvent = (event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };
    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);
    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [src]);
  return status;
};
export {
  ADJUST_EVENT_NAMES,
  Button1$1 as Button1,
  Button2$1 as Button2,
  Button3$1 as Button3,
  Button4$1 as Button4,
  Close,
  Column,
  LoadingSpinner,
  LogoFull,
  LogoShort,
  Modal,
  Overlay,
  Place,
  Row,
  Text,
  addAdjustSessionCallbackParams,
  colorPalette,
  initializeAdjust,
  logAdjustEvent,
  sendAdjustRevenueEvent,
  useScript,
};
