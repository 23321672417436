import React, { useCallback, useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { colorPalette } from '@lib/nvstr-common-ui.es';
import LoadingScreen from '@components/UI/LoadingScreen';
import { isSignedIn } from '@utils/services';
import { PUBLIC_ROUTES } from '@constants/publicRoutes';
import './../base.css';
import '../styles/globals.css';
import { InitializeAdjustWithConfig } from '@utils/adjust';
import { useRouter } from 'next/router';
import { TrackIteratively } from '@utils/itly';
import itly from '@utils/itly/itly.service';
import { analyticsPageNames } from '@utils/itly/analyticTypes';

function MyApp({ Component, pageProps }) {
  const [isMobile, setIsMobile] = useState(null);
  const [isLeavingSite, setIsLeavingSite] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      itly.initialize();
    }
  }, [router.isReady]);

  useEffect(() => {
    const screen = analyticsPageNames[router.pathname];
    const properties = {
      location: screen,
      url: window.location.pathname,
      url_query: window.location.search,
    };
    TrackIteratively.viewed.PAGE_VIEWED.send(properties);
  }, [router]);

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    if (isNaN(width)) {
      return;
    }
    const newWidthIsMobile = width < 1000;
    setIsMobile(newWidthIsMobile);
  }, []);

  const forwardToApp = () => {
    const {
      location: { href },
    } = window;
    const params = href.split('?');
    const urlParams = params.length && params[1] ? `?${params[1]}` : '';
    setIsLeavingSite(true);
    window.location = `/app${urlParams}`;
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    const {
      location: { pathname },
    } = window;
    if (!PUBLIC_ROUTES.includes(pathname)) {
      isSignedIn({ forwardToApp });
    }
  }, []);

  const textColor = {
    lightTheme: colorPalette.primary.charcoal,
    darkTheme: colorPalette.primary.oak,
  };

  const theme = {
    ...colorPalette,
    textColor,
    isMobile,
  };

  return (
    <ThemeProvider theme={theme}>
      {isLeavingSite ? <LoadingScreen /> : null}
      <InitializeAdjustWithConfig />
      <Component {...pageProps} />
    </ThemeProvider>
  );
}

export default MyApp;
