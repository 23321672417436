import { ItlyBrowser as ItlySdk } from '@itly/sdk';
import SegmentPlugin from '@itly/plugin-segment';

const environment = process.env.NEXT_ENVIRONMENT || 'development';

const Logger = {
  debug: (e) => {
    console.log('itly debug: ', { e });
  },
  info: (e) => {
    console.log('itly info: ', { e });
  },
  warn: (e) => {
    console.log('itly warn: ', { e });
  },
  error: (e) => {
    console.log('itly error: ', { e });
  },
};

export const SEGMENT_OPTIONS = {
  trackAppLifecycleEvents: false,
  integrations: {
    Amplitude: true,
    Adjust: true,
  },
};

export const getSegmentPublicAPIKey = () => {
  let publicKey = process.env.NEXT_PUBLIC_SEGMENT_KEY;
  if (!publicKey) {
    console.warn('segment key not found, defaulting...');
    publicKey = 'nMSBRenoRan8uRNkA2TeHbCknZDYZZDH';
  }
  return publicKey;
};

const itlyConfig = {
  validation: {
    disabled: false,
    trackInvalid: true,
    errorOnInvalid: false,
  },
  logger: Logger,
  environment,
  disabled: false,
  plugins: [new SegmentPlugin(getSegmentPublicAPIKey(), SEGMENT_OPTIONS)],
};

class Itly {
  config;
  constructor(itlyConfig) {
    if (typeof Itly.instance === 'object') {
      return Itly.instance;
    }
    this.config = itlyConfig;
    Itly.instance = this;
    return this;
  }

  initialize() {
    this._itly = new ItlySdk();
    this._itly.load(this.config);
  }

  /**
   * Alias a user ID to another user ID.
   * @param {string} userId The user's new ID.
   * @param {string} previousId The user's previous ID.
   * @param {AliasOptions} [options] Options for this alias call.
   */
  alias(userId, previousId, options) {
    if (!this._itly) {
      return;
    }
    this._itly.alias(userId, previousId, options);
  }

  /**
   * Identify a user and set or update that user's properties.
   * @param {string} [userId] The user's ID.
   * @param {Object} properties The user's properties.
   * @param {string} properties.lastName The user's last name.
   * @param {string} properties.firstName The user's first name.
   * @param {IdentifyOptions} [options] Options for this identify call.
   */
  identify(userId, properties, options) {
    if (!this._itly) {
      return;
    }
    this._itly.identify(userId, properties, options);
  }

  /**
   * Associate the current user with a group.
   * @param {string} groupId The group's ID.
   * @param {GroupOptions} [options] Options for this group call.
   */
  group(groupId, options) {
    if (!this._itly) {
      return;
    }
    this._itly.group(groupId, undefined, options);
  }

  /**
   * Track a page view.
   * @param {string} category The page's category.
   * @param {string} name The page's name.
   * @param {PageOptions} [options] Options for this page call.
   */
  page(category, name, options) {
    if (!this._itly) {
      return;
    }
    this._itly.page(category, name, undefined, options);
  }

  /**
   * @param {string} name The event name.
   * @param {object} properties .
   * @example  itly.track(events.emailConfirmed, {})
   */
  track(name, properties) {
    if (!this._itly) {
      return;
    }
    this._itly.track({ name, properties });
  }

  /**
   * Reset (e.g. on logout) all analytics state for the current user and group.
   */
  reset() {
    if (!this._itly) {
      return;
    }
    this._itly.reset();
  }

  flush() {
    if (!this._itly) {
      return;
    }
    return this._itly.flush();
  }
}

const itly = new Itly(itlyConfig);

export default itly;
