const BASEURL = process.env.NEXT_PUBLIC_API_URL;

const validURLEnvs = ['dev', 'qa'];

export const getBaseUrl = () => {
  // https://dev-api.tornado.com
  try {
    const origin = window.location.origin.split('https://')[1];
    const urlEnv = origin.split('.')[0];
    if (validURLEnvs.includes(urlEnv)) {
      return 'https://' + urlEnv + '-api.tornado.com';
    }

    return BASEURL;
  } catch (e) {
    console.error(e);
    return BASEURL;
  }
};
